import React from "react";
import "./WhatToBring.css";

const WhatToBring = () => {
  return (
    <div className="container custom-container whattobring-container mb-5">
      <h5 className="text-center mb-4 whattobring-heading">What to Bring</h5>
      <div className="row">
        <div className="col-12">
          <h6 className="whattobring-section-title mb-4">
            Required Items (not provided at the center)
          </h6>
          <ul className="whattobring-list mb-4">
            <li className="body1 whattobring-list-item mb-4 mb-4">
              <strong>Alarm Clock</strong>, torch and lock.{" "}
              <strong>
                (Please note that You will not be able to use your cell phone or
                other personal electronic device as an alarm clock)
              </strong>
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Enough comfortable and modest clothing. Modest dress is required
              for both men and women.
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Any medication you are taking. Please make sure you bring enough
              for the entire course.
            </li>
          </ul>

          <h6 className="whattobring-section-title mb-4">Suggested Items</h6>
          <ul className="whattobring-list mb-4">
            <li className="body1 whattobring-list-item mb-4">
              Indoor footwear/slippers: easy slip-ons e.g. clogs, flip flops,
              etc.
            </li>
            <li className="body1 whattobring-list-item mb-4">
              A shawl or light blanket for use in the meditation hall, as
              meditation hall is fully air conditioned.
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Seasonal items like raincoat, umbrella etc.
            </li>
          </ul>

          <h6 className="whattobring-section-title mb-4">We provide</h6>
          <ul className="whattobring-list mb-4">
            <li className="body1 whattobring-list-item mb-4">
              Meditation cushion.
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Bedding: sleeping pillow, bed cover and one light blanket.
            </li>
          </ul>

          <h6 className="whattobring-section-title mb-4">
            Please Do Not Bring
          </h6>
          <ul className="whattobring-list mb-4">
            <li className="body1 whattobring-list-item mb-4">
              <strong>Inappropriate Clothing:</strong> Tight, transparent,
              revealing or otherwise striking clothing (such as low risers,
              shorts, short skirts, tights, leggings, sleeveless, tank or skimpy
              tops)
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Cell phones and other personal electronic devices
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Computers or other electronic equipment
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Books, diaries, journals and other reading/writing materials
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Musical instruments
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Personal food items
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Tobacco in any form
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Non-prescribed drugs
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Scented toiletries or perfumes
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Religious or spiritual objects
            </li>
            <li className="body1 whattobring-list-item mb-4">
              Jewelry or other unnecessary valuables
            </li>
          </ul>
          <p className="body1 whattobring-note mt-2">
            <strong>NOTE:</strong> If you arrive with any of the above items,
            you will be requested to deposit them with the management until the
            end of the course.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhatToBring;
