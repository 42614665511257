import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Home.css";
import video1 from "./assets/DPattana Vdo.mp4";
import Services from "./Services/Services";
import AboutTheCourses from "./assets/home1.jpg";
import AboutTheCenter from "./assets/home2.jpg";

const Home = () => {
  const navigate = useNavigate();
  const coursesRef = useRef(null);

  const handleButtonClick = (link) => {
    navigate(link);
  };

  const scrollToCourses = () => {
    if (coursesRef.current) {
      const offset = 100;
      const topPosition = coursesRef.current.offsetTop - offset;
      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <div className="carousel-container">
        <div className="carousel-content position-relative">
          <div className="media-box">
            <video className="video-background" autoPlay loop muted about="Dhamma Pattana Intro Video">
              <source src={video1} type="video/mp4" />
            </video>
          </div>
          <div className="position-absolute top-50 start-50 translate-middle text-center">
            <p className="dhammatext">
              VIPASSANA MEDITATION AS TAUGHT BY S.N. GOENKA IN THE TRADITION OF
              SAYAGYI U BA KHIN
            </p>
            <h1 className="text-white dhammaheading">DHAMMA PATTANA</h1>
            <p className="text-white mt-4 dhammatext">
              OFFERING VIPASSANA COURSES ESPECIALLY FOR BUSINESS EXECUTIVES,
              PROFESSIONALS, AND GOVERNMENT OFFICIALS
            </p>
            <button className="btn btn-warning mt-5" onClick={scrollToCourses}>
              LEARN MORE
            </button>
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="mt-5 vedios-image-content" ref={coursesRef}>
          <div className="col-lg-6 d-flex aboutVip">
            <div className="g-5 vedios-image-self special-first">
              <iframe
                className="image-vedios"
                src="https://www.youtube.com/embed/P97criit1qI"
                title="vedio1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div className="col-lg-6  align-items-center vedio-image">
            <h5>Vipassana</h5>
            <p className="body1">
              The technique of Vipassana meditation is a practical way to
              achieve peace of mind and live a happy productive life. It is
              learned by attending a 10- day residential course with a qualified
              teacher where the student is free from distractions so that the
              reality within can be observed. This technique helps practitioners
              come out of suffering; it is non-sectarian and is suitable to all
              people regardless of religion, gender, race or nationality.
            </p>
            <p className="body1">
              Before applying to a course you should read about the technique
              and accept the code of discipline which explains what Vipassana is
              and is not, and what is expected of you when attending a course,
              which is a serious undertaking.
            </p>
            <button
              type="button"
              className=" mt-1 custom-outline-btn"
              onClick={() => handleButtonClick("./what-is-vipassana")}
            >
              Read More
            </button>
          </div>
        </div>

        <div className="mt-5 vedios-image-content special-second">
          <div className="col-lg-6 vedio2 ">
            <h5>Courses</h5>
            <p className="body1">
              All courses offered at the Vipassana Meditation Center, Dhamma
              Pattana, are conducted by assistant teachers of SN Goenka using
              audio and video recordings of Mr. Goenka’s instructions and
              discourses. All ten day courses begin the evening of the arrival
              day and end early morning of the departure day. There are no
              charges for the courses – not even to cover the cost of food and
              accommodation.
            </p>
            <p className="body1">
              Please note that many courses are fully booked 2-3 months in
              advance. We encourage you to apply early in order to allow time to
              make travel arrangements once registered. An application is
              required to register for all courses. Please apply to sit only one
              course at a time. Any additional applications will not be
              processed.
            </p>
            <button
              type="button"
              className=" mt-1 custom-outline-btn"
              onClick={() => handleButtonClick("./how-to-apply")}
            >
              How To Apply
            </button>
          </div>
          <div className="col-lg-6 d-flex aboutCou ">
            <div className="g-5 image-self">
              <img src={AboutTheCourses} alt="" className="image-vedios" />
            </div>
          </div>
        </div>
        <div className="image-space vedios-image-content">
          <div className="col-lg-6 d-flex aboutDha">
            <div className="g-5 vedios-image-self special-third">
              <img src={AboutTheCenter} alt="" className="image-vedios" />
            </div>
          </div>
          <div className="col-lg-6 vedio-image">
            <h5>Dhamma Pattana</h5>
            <p className="body1">
              Dhamma Pattana, meaning the "Harbour of Dhamma", is located on a
              hill overlooking the beach town of Gorai, in the outer northern
              suburbs of Mumbai. It is a part of the renowned Global Pagoda
              complex that opened in 2009. Get sufficient time to practice so as
              all residential rooms are equipped with western facilities and air
              conditioning.This center provides facilities suitable to
              executives and professionals used to a different lifestyle, though
              the schedule and the teachings remain the same across the centers.
            </p>
            <p className="body1">
              Before applying to a course you should read about the technique
              and accept the code of discipline which explains what Vipassana is
              and is not, and what is expected of you when attending a course,
              which is a serious undertaking.
            </p>
            <button
              type="button"
              className="mt-1 custom-outline-btn"
              onClick={() => handleButtonClick("./about-us")}
            >
              Read More
            </button>
          </div>
        </div>

        <Services />
      </div>
    </>
  );
};

export default Home;
