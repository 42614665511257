import React from "react";
import { useNavigate } from "react-router-dom";
import DhammaService from "./assets/Dhamma service.jpg";
import Donation from "./assets/Donation.jpg";
import Resources from "./assets/Resources.jpg";
import "./ServicesLogin.css";

const ServicesLogin = () => {
  const navigate = useNavigate();

  const services = [
    {
      id: 1,
      title: "DHAMMA SERVICE",
      img: DhammaService,
      route: "/services",
    },
    {
      id: 2,
      title: "DONATION",
      img: Donation,
      route: "/donation",
    },
    {
      id: 3,
      title: "RESOURCES",
      img: Resources,
      link: "https://os.vridhamma.org/",
    },
  ];

  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <div className="old-page d-flex justify-content-center align-items-center mt-5 ">
      <div className="container mt-5">
        <h5 className=" mb-4 text-center mt-5 old-page">OLD STUDENT</h5>
        <div className="row mt-5 mb-5">
          {services.map((service) => (
            <div className="col-md-4 mb-4" key={service.id}>
              <div className="service-card position-relative">
                <img
                  src={service.img}
                  className="img-fluid service-img"
                  alt={service.title}
                />
                <div className="overlay d-flex align-items-center justify-content-center">
                  {service.route ? (
                    <button
                      className="btn btn-warning"
                      onClick={() => handleNavigate(service.route)}
                    >
                      MORE
                    </button>
                  ) : (
                    <a href={service.link}>
                      <button
                        className="btn btn-warning"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        MORE
                      </button>
                    </a>
                  )}
                </div>
              </div>
              <div className="service-info mt-3">
                <div className="card-heading">
                  <h6 className="card-heading-heading">{service.title}</h6>
                </div>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesLogin;
