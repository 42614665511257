import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Services.css";
import HowToApplyImg from "../assets/howtoapply.jpg";
import AboutTheCenterImg from "../assets/about the centre.jpg";
import OldStudentImg from "../assets/old students.jpg";

const Services = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authStatus = localStorage.getItem("isAuthenticated") === "true";
    setIsAuthenticated(authStatus);
  }, []);

  const services = [
    {
      id: 1,
      title: "How to Apply",
      img: HowToApplyImg,
      route: "/how-to-apply",
    },
    {
      id: 2,
      title: "About the Center",
      img: AboutTheCenterImg,
      route: "/about-us",
    },
    {
      id: 3,
      title: "Old Students",
      img: OldStudentImg,
      route: !isAuthenticated ? "/login" : "/services-login",
    },
  ];

  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-8 mx-auto text-center">
          <h6 className="mb-2">We offer</h6>
          <h5 className="mb-4">Residential Courses in Vipassana Meditation</h5>
          <p className="body1">
            The technique of Vipassana Meditation is taught at 10-day
            residential courses during which participants learn the basics of
            the method and practice sufficiently to experience its beneficial
            results in daily life.
          </p>
          <p className="body2 mt-4 mb-4">COURSES IN OTHER LOCATIONS</p>
          <p className="body1">
            Vipassana courses are held in other meditation centers, and at
            non-center locations at rented sites. Each location has its own
            schedule of courses. View all Maharashtra centers and locations, and
            other locations worldwide, in the{" "}
            <a
              href="https://www.dhamma.org/en-US/locations/directory#ma-in"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dhamma.org website
            </a>{" "}
            .
          </p>
          <p className="body1">
            Vipassana courses as taught by S N Goenka, in tradition of Sayagyi U
            Ba Khin are also held at other locations in India and abroad,
            besides being organised at rented sites as non-center locations
            also…..{" "}
          </p>
        </div>
      </div>

      <div className="row mt-5 mb-5">
        {services.map((service, index) => (
          <div className="col-md-4 mb-4" key={service.id}>
            <div className="service-card position-relative">
              <img
                src={service.img}
                className="img-fluid service-img"
                alt={service.title}
              />

              <div className="overlay d-flex align-items-center justify-content-center">
                <button
                  className="btn btn-warning"
                  onClick={() => handleNavigate(service.route)}
                >
                  MORE
                </button>
              </div>
            </div>
            <div className="service-info mt-3">
              <div className="card-heading">
                <h6 className="card-heading-heading">{service.title}</h6>
              </div>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
