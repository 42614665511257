import React from "react";
import "./AboutUs.css";
import Center17 from "../assets/center17.jpg";
import Center18 from "../assets/center18.jpg";
import Carousel from "../../../components/Carousel/Carousel";

const Slides = [
  { img: Center17, label: "View of dining hall male side." },
  { img: Center18, label: "Entrance of dining hall from male side." },
];

const MeditationHall = ({ onImageClick }) => {
  const handleOnImageClick = (index) => {
    onImageClick(Slides, index)
  }
  return (
    <div>
      <section className="section  desktop-gallery">
        <h2 className="section-title">Dining</h2>
        <p className="body1 all-txts">
          Students are allotted seats at the beginning of the course which they
          are supposed to use for the entire course. Students are supposed to
          adhere to the dining time-table. They are not allowed to keep food in
          their rooms except with teachers prior permission in special cases
          like illness or pregnancy.
        </p>
        <Carousel slides={Slides} onImageClick={handleOnImageClick} />
      </section>
    </div>
  );
};

export default MeditationHall;
