import React from "react";
import "./AboutUs.css";
import Carousel from "../../../components/Carousel/Carousel";

import Gallery1 from "../../TheCentre/assets/3.jpg";
import Gallery2 from "../../TheCentre/assets/6.jpg";
import Gallery3 from "../../TheCentre/assets/7.jpg";
import Gallery4 from "../../TheCentre/assets/8.jpg";
import Gallery5 from "../../TheCentre/assets/center17.jpg";
import Gallery6 from "../../TheCentre/assets/img2.jpg";
import Gallery8 from "../../TheCentre/assets/img4.jpg";
import Gallery9 from "../../TheCentre/assets/img5.jpg";
import Gallery10 from "../../TheCentre/assets/img8.jpg";

const Slides = [
  { img: Gallery1, label: "View of garden and Mumbai from terrace." },
  { img: Gallery2, label: "Colourful flower on pathway." },
  { img: Gallery3, label: "Registration area male side." },
  { img: Gallery4, label: "Colourful leaves." },
  { img: Gallery5, label: "View of dining hall male side." },
  { img: Gallery6, label: "View of the centre from female walking area." },
  { img: Gallery8, label: "Panoramic view of Dhamma hall." },
  { img: Gallery9, label: "Residence with view of Pagoda from the window." },
  { img: Gallery10, label: "View from global Pagoda." },
];

const PhotoGallery = ({ onImageClick }) => {
  const handleOnImageClick = (index) => {
    onImageClick(Slides, index)
  }

  return (
    <div>
      <section className="section">
        <h2 className="section-title">Photo Gallery</h2>
        <Carousel slides={Slides} onImageClick={handleOnImageClick} />
      </section>
    </div>
  );
};

export default PhotoGallery;
