import React from "react";
import img from "./guruji.jpg";
import "./Services.css";

function Services() {
  return (
    <div className="container custom-container text-center'">
      <h5 className=" mb-4 text-center">Dhamma Service </h5>
      <div className="d-flex flex-column align-items-center text-center mt-4 mb-4">
        <div className="d-flex flex-row service-vedio-cont">
          <div className="service-vedio-cont1">
            <iframe
              className="sevice-ved1"
              width="560px"
              height="350px"
              style={{ borderRadius: "10px" }}
              src="https://www.youtube.com/embed/D4jcs_C8W5c?si=cdrdBOHp93H1rgs2"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; 
                     autoplay; clipboard-write; 
                     encrypted-media; gyroscope; 
                     picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div className="service-vedio-cont2">
            <iframe
              className="sevice-ved1"
              width="560px"
              height="350px"
              style={{ borderRadius: "10px" }}
              src="https://www.youtube.com/embed/3Jrc48B5WpU?si=3fN0ZsJ1vDdR1tbd"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; 
                     autoplay; clipboard-write;
                     encrypted-media; gyroscope; 
                     picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <p className="body1 mt-5">
          “May all suffering people of the world understand what is Dhamma, may
          they practice Dhamma, may they apply Dhamma in their day-to-day life
          and come out of all the agonies of a defiled mind full of
          negativities. May all enjoy the peace and harmony of a pure mind, a
          mind full of compassionate love and goodwill towards other beings.” -
          S.N. Goenka
        </p>
        <h5 className="mt-4 mb-4">Value of Dhamma Service</h5>
        <p className="body1">
          The giving of Dhamma Service, whether in a course or in some other way
          to help in the spread of Dhamma, is one of the most valuable
          experiences a student of Vipassana can have. Not only it is rewarding
          to know that you have helped others to taste the Nectar of Dhamma, but
          it also is a wonderful source of growth and strength in one's own
          development in meditation practice. In daily life, there are many ups
          and downs. To maintain equanimity and to generate love & compassion
          despite these vicissitudes is the training of Vipassana. When
          Vipassana meditators give Dhamma service, they learn in a healthy
          atmosphere how to apply Dhamma in life. This is a training ground for
          each server to learn how to live a good life.
        </p>
        <h5 className="mt-4 mb-4">Who can serve?</h5>
        <p className="body1">
          Any student who has successfully completed a 10-day Vipassana
          Meditation course with Mr. S. N. Goenka or one of his assistant
          teachers, and has not practiced any other meditation techniques since
          their last course.
        </p>
        <h5 className="mt-4 mb-4">Ways to serve</h5>
        <p className="body1">
          There are different ways to give Dhamma seva; by serving courses full
          time/ part time, by visiting center for some time & serving as per
          instructions of course manager or by participating in one of the
          projects at the center. Currently, Center is in developing stage &
          various activities are going on for benefits of students. Construction
          of office building, teacher's residence and landscaping are prominent
          activities.
        </p>
      </div>
    </div>
  );
}

export default Services;
