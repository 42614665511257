import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./RelatedWebsites.css";

const RelatedWebsites = () => {
  const websites = [
    { name: "International Vipassana Website", url: "https://www.dhamma.org" },
    { name: "Vipassana Research Institute", url: "https://www.vridhamma.org" },
    { name: "Global Vipassana Pagoda", url: "http://www.globalpagoda.org" },
    {
      name: "Vipassana courses for Business Executives",
      url: "https://executive.dhamma.org",
    },
    {
      name: "Anapana Meditation for children",
      url: "https://www.vridhamma.org/Children-Courses",
    },
    {
      name: "Mini Anapana Meditation for all",
      url: "https://www.vridhamma.org/Mamfa",
    },
    { name: "Vipassana in prison", url: "http://www.prison.dhamma.org" },
  ];

  const remainingCards = websites.length % 3;

  return (
    <div className="container custom-container mb-5">
      <h5 className="text-center mb-2">Related Websites</h5>
      <p className="text-center subtitle1 mb-5">
        For more information about Vipassana meditation, please visit the
        following related websites:
      </p>

      <div className="row justify-content-center">
        {websites.map((website, index) => {
          const isLastRow = index >= websites.length - remainingCards;
          const colSize = remainingCards === 2 ? "col-md-5" : "col-md-4";

          return (
            <div
              key={index}
              className={`mb-4 d-flex ${isLastRow ? colSize : "col-md-4"}`}
            >
              <a
                href={website.url}
                className="card-link w-100"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="card w-100">
                  <div className="card-body">
                    <h6 className="card-title">{website.name}</h6>
                    <div className="btn-center mt-auto">
                      <span>Visit Website</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RelatedWebsites;
