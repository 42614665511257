import React, { useState, useEffect, useRef } from "react";
import "./AboutUs.css";
import Welcome from "./Welcome";
import Overview from "./Overview";
import PhotoGallery from "./PhotoGallery";
import MeditationHall from "./MeditationHall";
import Pagoda from "./Pagoda";
import Residence from "./Residence";
import Dining from "./Dining";
import Maps from "./Maps";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import ImageModal from "../../../components/ImageModal/ImageModal";

const AboutUs = () => {
  const [isFixed, setIsFixed] = useState(false);

  const overviewRef = useRef(null);
  const photoGalleryRef = useRef(null);
  const meditationHallRef = useRef(null);
  const pagodaRef = useRef(null);
  const residencesRef = useRef(null);
  const diningRef = useRef(null);

  const [selectedModalGallery, setSelectedModalGallery] = useState(undefined)
  const [selectedModalGalleryIndex, setSelectedModalGalleryIndex] = useState(undefined);

  useEffect(() => {
    const handleScroll = () => setIsFixed(window.scrollY > 400);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (ref) => {
    const offset = 100; // Height of the sticky navigation bar
    const top = ref.current.offsetTop - offset;
    window.scrollTo({ top, behavior: "smooth" });
  }

  const onImageClick = (gallery, imageIndex) => {
    console.log(selectedModalGallery)
    setSelectedModalGallery(gallery);
    setSelectedModalGalleryIndex(imageIndex)
  }

  const onModalNextClick = () => {
    if (selectedModalGallery === undefined) return;
    if (selectedModalGalleryIndex === undefined) return;
    if (selectedModalGalleryIndex === selectedModalGallery.length - 1) return setSelectedModalGalleryIndex(0);
    setSelectedModalGalleryIndex(selectedModalGalleryIndex + 1)
  }

  const onModalPrevClick = () => {
    if (selectedModalGallery === undefined) return;
    if (selectedModalGalleryIndex === undefined) return;
    if (selectedModalGalleryIndex === 0) return setSelectedModalGalleryIndex(selectedModalGallery.length - 1);
    setSelectedModalGalleryIndex(selectedModalGalleryIndex - 1)
  }

  const onModalClose = () => {
    setSelectedModalGallery(undefined);
    setSelectedModalGalleryIndex(undefined);
  }

  const showModal = selectedModalGallery !== undefined && selectedModalGalleryIndex !== undefined;


  return (
    <>
      <div className="container custom-container the-centre-cont mb-4 mt-5 d-flex">
        <div className="center-cont mt-5">
          <Welcome />
          <div ref={overviewRef}>
            <Overview onImageClick={onImageClick} />
          </div>
          <div ref={photoGalleryRef}>
            <PhotoGallery onImageClick={onImageClick} />
          </div>
          <div ref={meditationHallRef}>
            <MeditationHall onImageClick={onImageClick} />
          </div>
          <div ref={pagodaRef}>
            <Pagoda onImageClick={onImageClick} />
          </div>
          <div ref={residencesRef}>
            <Residence onImageClick={onImageClick} />
          </div>
          <div ref={diningRef}>
            <Dining onImageClick={onImageClick} />
          </div>
          <Maps />
        </div>

        <div className={`scroll-cont scroll-desktop ${isFixed ? "fixed" : ""}`}>
          <div className="scroll-cont-in">
            <h6>Dhamma Pattana</h6>
            <div className="all-linked-txt">
              <p
                className="center-txt"
                onClick={() => scrollToSection(overviewRef)}
              >
                Overview
              </p>
              <p
                className="center-txt"
                onClick={() => scrollToSection(photoGalleryRef)}
              >
                Photo Gallery
              </p>
              <p
                className="center-txt"
                onClick={() => scrollToSection(meditationHallRef)}
              >
                Meditation Hall
              </p>
              <p
                className="center-txt"
                onClick={() => scrollToSection(pagodaRef)}
              >
                Pagoda
              </p>
              <p
                className="center-txt"
                onClick={() => scrollToSection(residencesRef)}
              >
                Residences
              </p>
              <p
                className="center-txt"
                onClick={() => scrollToSection(diningRef)}
              >
                Dining
              </p>
            </div>
          </div>
        </div>
      </div>

      {showModal &&
        <ImageModal imageSrc={selectedModalGallery[selectedModalGalleryIndex].img} imageAlt={selectedModalGallery[selectedModalGalleryIndex].alt} handleNextClick={onModalNextClick} handlePrevClick={onModalPrevClick} handleClose={onModalClose} />
      }

    </>
  );
};

export default AboutUs;
