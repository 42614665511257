import React from "react";
import "./AboutUs.css";

import Center13 from "../../TheCentre/assets/center13.jpg";
import Center14 from "./../../TheCentre/assets/center14.jpg";
import Center15 from "../../TheCentre/assets/center15.jpg";
import Center16 from "../../TheCentre/assets/center16.jpg";
import Carousel from "../../../components/Carousel/Carousel";

const Slides = [
  { img: Center13, label: "Female rooms" },
  { img: Center14, label: "Male rooms" },
  { img: Center15, label: "Bathroooms" },
  { img: Center16, label: "Passage areas with lift" }
];

const Residence = ({ onImageClick }) => {
  const handleOnImageClick = (index) => {
    onImageClick(Slides, index)
  }
  return (
    <div>
      <section className="section  desktop-gallery">
        <h2 className="section-title"> Residences</h2>
        <p className="body1 all-txts">
          Male and Female residences are segregated. Each room has an attached
          bathroom with hot water facilities. Rooms being air-conditioned, one
          is advised to bring appropriate woolens. The center is a compact
          two-storey building and one is required to climb two stairs, 3 to 4
          times a day. However there is an elevator available for use by the
          elderly and needy. Accommodation is usually individual, however in
          certain unavoidable circumstances one may have to share a room with
          another participant.
        </p>
        <Carousel slides={Slides} onImageClick={handleOnImageClick} />

      </section>
    </div>
  );
};

export default Residence;
