import React from "react";
import "./AboutUs.css";
import Center9 from "../../TheCentre/assets/center9.jpg";
import Center10 from "../../TheCentre/assets/center10.jpg";
import Center11 from "../../TheCentre/assets/center11.jpg";
import Center12 from "../../TheCentre/assets/center12.jpg";
import Carousel from "../../../components/Carousel/Carousel";


const Slides = [
  { img: Center10, label: "Beautiful silhouette of pagoda after sunset" },
  { img: Center11, label: "Stairs to cells" },
  { img: Center9, label: "Inside of meditation cells" },
  { img: Center12, label: "Panoramic view of circular pagoda inside" },
];

const Pagoda = ({ onImageClick }) => {
  const handleOnImageClick = (index) => {
    onImageClick(Slides, index)
  }
  return (
    <div>
      <section className="section  desktop-gallery">
        <h2 className="section-title">Pagoda</h2>
        <p className="body1 all-txts">
          At the end of the walkway on the other side of the Dhamma hall is the
          Pagoda also called Shunyagar. The circular structure has the Principal
          teacher cell in the center. Students get an opportunity to practice
          the task for the day for some time during the day as mentioned in the
          schedule in individual cells allotted to them without any disturbance.
          This comes from the concept of individual cells for monks in viharas
          at the time of Buddha. Female entry to Pagoda is from the walkway of
          their residences whereas Male students use the entry from outside of
          the Dhamma hall.
        </p>
        <Carousel slides={Slides} onImageClick={handleOnImageClick} />
      </section>
    </div>
  );
};

export default Pagoda;
