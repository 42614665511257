import React from "react";
import "./AboutUs.css";
// Import in your main file (e.g., index.js)
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Center8 from "../../TheCentre/assets/center8.jpg";
import Center9 from "../../TheCentre/assets/center9.jpg";
import Carousel from "../../../components/Carousel/Carousel";

const Slides = [
  { img: Center8, label: "Interior of Meditation hall." },
  { img: Center9, label: "Hall view from male side entrance." },
];


const MeditationHall = ({ onImageClick }) => {
  const handleOnImageClick = (index) => {
    onImageClick(Slides, index)
  }
  return (
    <div>
      <section className="section">
        <h2 className="section-title">Meditation Hall</h2>
        <p className="body1 all-txts">
          Also called Dhamma Hall is on the 2nd Floor having Male and Female
          residences on either side of the hallway. The teacher rooms and the
          Mini Dhamma hall are adjacent to the Meditation hall. Mini Dhamma hall
          is used for interviewing students personally and also as other
          language halls if required.
        </p>
        <Carousel slides={Slides} onImageClick={handleOnImageClick} />

      </section>

    </div>
  );
};

export default MeditationHall;
