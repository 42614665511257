import React from "react";
import "./AboutUs.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Center1Video from "../../Home/assets/DPattana Vdo.mp4";

const Welcome = () => {
  return (
    <>
      <section className="section">
        <h2 className="section-title">Welcome to Dhamma Pattana</h2>
        <video
          style={{ width: "100%" }}
          src={Center1Video}
          alt="Pagoda Exterior"
          autoPlay
          muted
          loop
          playsInline
          controls
        />
        <p className="body1 mt-3 all-txts">
          Dhamma Pattana, means “Harbor of Dhamma” in Pali Language. It is one
          of numerous centers worldwide dedicated to the teaching of Vipassana
          Meditation as taught by{" "}
          <span style={{ color: "#3590db" }}> S.N. Goenka</span> in the
          tradition of Sayagyi U Ba Khin.
        </p>
        <p className="body1 all-txts">
          Dhamma Pattana uniquely caters to the needs of Executives and
          Professionals to learn the technique considering their modern
          lifestyle. It is a part of the majestic Global Vipassana Pagoda (GVP)
          Complex. The Global Pagoda stands tall, emanating powerful meditative
          vibrations from the precious relics of Buddha enshrined in a chamber
          at the top of it. GVP, one of the dream projects of Goenkaji, stands
          as a mark of tribute and gratitude towards the Land and the chain of
          teachers who preserved this technique in its pristine purity. GVP and
          Dhamma Pattana along with the other aspects like The Bodhi tree, The
          Ashoka Pillar, The Museum, The Art Gallery, The Pali Bhavan,
          Dhammalaya are all set in a single complex for one to have a glimpse
          into the rich and rare to encounter experiences as given by The
          Enlightened One to the world. Executives and Professionals are given
          the priority at this center as it is located in the close proximity of
          one of the busiest and densely populated cities of the country.
        </p>
      </section>
    </>
  );
};

export default Welcome;
