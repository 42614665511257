import React, { useState } from "react";
import "./GoenkaPage.css";
import Goenkaji from "./Goenkaji.jpg";

const GoenkaPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <div className="container custom-container mb-5">
        <div className="row">
          <div className="col-md-5">
            <img
              src={Goenkaji}
              alt="Mr. S.N. Goenka"
              className="goenkaji mb-3"
            />
          </div>

          <div className="col-md-7 d-flex">
            <div className="ms-md-4 vedio-text">
              <h5 className="mb-3">Mr. S.N. Goenka</h5>

              <p className="body1">
                Satya Narayan Goenka was the foremost lay teacher of Vipassana
                meditation of our time.
              </p>
              <p className="body1">
                Although Indian by descent, Mr. Goenka was born and raised in
                Myanmar (Burma). While living there, he had the good fortune to
                come into contact with Sayagyi U Ba Khin and to learn the
                technique of Vipassana from him. After receiving training from
                his teacher for 14 years, Mr. Goenka settled in India and began
                teaching Vipassana in 1969.
              </p>
              <p className="body1">
                In a country still sharply divided by differences of caste and
                religion, the courses offered by Mr. Goenka soon attracted
                thousands of people from every part of society. In addition,
                many people from countries around the world came to join courses
                in Vipassana meditation.{" "}
              </p>
              <p className="body1">
                Over a period of almost 45 years, Mr. Goenka and the teachers
                appointed by him taught hundreds of thousands of people in
                courses in India and other countries, East and West.
              </p>
              <p className="body1">
                Today, meditation centers established under his guidance are
                operating in Asia, Europe, the Americas, Africa, and
                Australasia.
              </p>
            </div>
          </div>
        </div>
        <div className="vedio-text">
          <p className="body1">
            The technique taught by S.N. Goenka goes back two and a half
            millennia to the Buddha. The Buddha never taught a sectarian
            religion; he taught Dhamma – the way to liberation – which is
            universal. In the same tradition, Mr. Goenka's approach is
            totally non-sectarian. For this reason, his teaching has had a
            profound appeal to people of all backgrounds, of every religion
            and no religion, and from every part of the world.
          </p>
          <p className="body1">
            Mr. Goenka was the recipient of many awards and honors in his
            lifetime, including a prestigious Padma Awards from the
            President of India in 2012. This is one of the highest civilian
            awards given by the Indian Government.
          </p>
          <p className="body1">
            Satya Narayan Goenka breathed his last in September 2013, at the
            age of 89. He has left behind an imperishable legacy: the
            technique of Vipassana, now available more widely than ever
            before to people around the world.
          </p>
          <h6>U.N. Peace Summit</h6>
          <p className="body1">
            In the Summer of 2000, Mr. Goenka, the principal teacher of
            Vipassana Meditation visited the United States and spoke, along
            with other world spiritual leaders, at the "Millennium World
            Peace Summit" at the United Nations World Headquarters in New
            York.
          </p>
          <h6>S.N. Goenka Addresses Peace Summit</h6>
          <p className="body1">By Bill Higgins</p>
          <p className="body1">Date: August 29, 2000</p>
          <p className="body1">
            NEW YORK - Vipassana Acharya S. N. Goenka addressed the
            delegates to the Millennium World Peace Summit as they gathered
            in the United Nations General Assembly Hall today - first ever
            gathering of religious and spiritual leaders in the UN.
          </p>
          <p className="body1">
            Mr. Goenka's speech, in the session entitled Conflict
            Transformation, focussed on the themes of religious harmony,
            tolerance and peaceful coexistence.
          </p>
          <p className="body1">
            "Rather than converting people from one organized religion to
            another organized religion," said Mr. Goenka, "we should try to
            convert people from misery to happiness, from bondage to
            liberation and from cruelty to compassion. "
          </p>
          <p className="body1">
            Mr. Goenka gave his speech during the Summit's afternoon session
            to a group that included roughly two thousand delegates and
            observers. Mr. Goenka spoke in the session that followed CNN
            founder Ted Turner's speech. Mr. Turner is one of the Summit's
            financial patrons.
          </p>
          <p className="body1">
            In keeping with the Summit's theme of seeking world peace, Mr.
            Goenka stressed in his speech that peace in the world cannot be
            achieved unless there is peace within individuals. "There cannot
            be peace in the world when people have anger and hatred in their
            hearts. Only with love and compassion in the heart is world
            peace attainable."
          </p>
          <p className="body1">
            An important aspect of the Summit is the effort to reduce
            sectarian conflict and tension. Regarding this Mr. Goenka said,
            "When there is anger and hatred within, one becomes miserable
            irrespective of whether one is a Christian or a Hindu or a
            Muslim."
          </p>
          <p className="body1">
            Similarly he said to a thunderous applause, "One who has love
            and compassion with a pure heart experiences the Kingdom of
            Heaven within. This is the Law of Nature, or if one would
            rather, God's will just check."
          </p>
          <p className="body1">
            Appropriately to a crowd that included major world religious
            leaders he said, "Let us focus on the commonalties of all
            religions, on the inner core of all religions which is purity of
            heart. We should all give importance to this aspect of religion
            and avoid conflict over the outer shell of the religions, which
            is various rites, rituals, festivals and dogmas."
          </p>
          <p className="body1">
            In summing up Mr. Goenka quoted the Emperor Ashoka who in one of
            his Rock Edicts said, "One should not honor only one's own
            religion and condemn other religions. Instead, one should honor
            other religions for various reasons. By so doing one helps one's
            own religion to grow and also renders service to the religions
            of others. In acting otherwise one digs the grave of one's own
            religion and harms other religions as well. Someone who honors
            his own religion and condemns other religions may do so out of
            devotion to his religion, thinking, "I will glorify my
            religion'; but his actions injure his own religion more gravely.
            Concord is good. Let all listen and be willing to listen to the
            doctrines professed by others."
          </p>
          <p className="body1">
            U.N. Secretary-General Kofi Annan has called the Summit "a
            gathering of the world's pre-eminent religious and spiritual
            leaders in a united call for peace that will hopefully
            strengthen the prospect for peace as we enter the new
            millennium."
          </p>
          <p className="body1">
            Spiritual leaders who've been invited to the U.N.'s first-ever
            conference of this kind include Pramukh Swami of Swami Narayana
            Movement, Swami Dayananda Saraswati, Swami Agniwesh, Mata
            Amritanandamayi Devi and Dada Wasvani as well as eminent
            scholars such as Dr Karan Singh and L. M. Singhvi.
          </p>
          <p className="body1">
            In reference to the participants' religious and cultural
            diversity, Annan has said, "the United Nations is a tapestry,
            not only of suits and saris but of clerics' collars, nuns'
            habits and lamas' robes; of miters, skullcaps and yarmulkes."
          </p>
          <p className="body1">
            Though Annan has been repeatedly questioned about the Tibetan
            leaders absence, he has attempted to steer questions back to the
            Summit's goal, which he says are "to restore religion to its
            rightful role as peacemaker and pacifier - the problem of
            conflict is never the Bible or the Torah or the Koran. Indeed,
            the problem is never the faith - it is the faithful and how we
            behave towards each other. You must, once again, teach your
            faithful the ways of peace and the ways of tolerance."
          </p>
          <p className="body1">
            The U.N. leader's hope is that since 83% of the world's
            population adheres to a formal religious or spiritual belief
            system, these religious leaders can influence their followers
            towards peace.
          </p>
          <p className="body1">
            The U.N. is hoping the conference will move the world community
            towards, in the words of one document, "to acknowledge its
            spiritual potential and recognize that it is within our power to
            eradicate the worst form of human brutality - war - as well as
            one of the root causes of war - poverty. The time is ripe for
            the world's spiritual leadership to work more closely with the
            United Nations in its effort to address the pressing needs of
            humankind."
          </p>
          <p className="body1">
            The Summit will end this Thursday on 31 August when participants
            will sign a Declaration for World Peace and form an
            International Advisory Council of Religious and Spiritual
            Leaders, which will work with the United Nations and the U.N.
            Secretary-General in peacemaking and peacekeeping efforts.
          </p>
          <p className="body1">
            "The goal of the International Advisory Council of Religious and
            Spiritual Leaders is to enhance and strengthen the work of the
            United Nations," said Bawa Jain, the Secretary-General of the
            World Peace Summit. "It is our earnest hope that in times of
            conflict, the world's great religious and spiritual leaders can
            be parachuted into these hotspot to seek non-violent resolutions
            to the conflicts."
          </p>
          <h6>U.N. Address</h6>
          <p className="body1">
            The following is the complete text of the address given by Mr.
            Goenka on Tuesday, 29 August 2000 in the United Nations General
            Assembly Hall to the participants of the Millennium World Peace
            Summit.
          </p>
          <h6>Universal Spirituality for Peace By S. N. Goenka</h6>
          <p className="body1">Date: August 29, 2000</p>
          <div className="row justify-content-center mb-3">
            <div
              className="col-12 p-2 video-container1"
              onClick={handleOpen}
            >
              <img
                src="https://img.youtube.com/vi/P97criit1qI/0.jpg"
                alt="Video Thumbnail"
                className="thumbnail"
              />

              <div className="play-button-first">
                <div className="play-button-second">
                  <i className="play-icon"></i>
                </div>
              </div>
            </div>

            {isOpen && (
              <div className="modal-background" onClick={handleClose}>
                <div className="hover-thumbnail">
                  <span className="close-button" onClick={handleClose}>
                    ×
                  </span>
                  <iframe
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "none",
                    }}
                    src="https://www.youtube.com/embed/P97criit1qI"
                    title="Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            )}
          </div>

          <p className="body1">
            When there is darkness, light is needed. Today, with so much
            agony caused by violent conflict, war and bloodshed, the world
            badly needs peace and harmony. This is a great challenge for
            religious and spiritual leaders. Let us accept this challenge.
          </p>
          <p className="body1">
            Every religion has an outer form or shell, and an inner essence
            or core. The outer shell consists of rites, rituals, ceremonies,
            beliefs, myths and doctrines. These vary from one religion to
            another. But there is an inner core common to all religions: the
            universal teachings of morality and charity, of a disciplined
            and pure mind full of love, compassion, goodwill and tolerance.
            It is this common denominator that religious leaders ought to
            emphasize, and that religious adherents ought to practice. If
            proper importance is given to the essence of all religions and
            greater tolerance is shown for their superficial aspects,
            conflict can be minimized.
          </p>
          <p className="body1">
            All persons must be free to profess and follow their faith. In
            doing so, however, they must be careful not to neglect the
            practice of the essence of their religion, not to disturb others
            by their own religious practices, and not to condemn or belittle
            other faiths.
          </p>
          <p className="body1">
            Given the diversity of faiths, how do we surmount the
            differences and achieve a concrete plan for peace? The Buddha,
            the Enlightened One, was often approached by people of different
            views. To them he would say, "Let us set aside our differences.
            Let us give attention to what we can agree on, and let us put it
            into practice. Why quarrel?" That wise counsel still retains its
            worth today.
          </p>
          <p className="body1">
            I come from an ancient land that has given rise to many
            different schools of philosophy and spirituality over the
            millennia. Despite isolated instances of violence, my country
            has been a model of peaceful co-existence. Some 2300 years ago
            it was ruled by Ashoka the Great, whose empire extended from
            present-day Afghanistan to Bangladesh. Throughout his realm,
            this compassionate ruler caused edicts to be inscribed on stone,
            proclaiming that all faiths should be respected; and as a
            result, followers of all spiritual traditions felt secure under
            his sway. He asked people to live a moral life, to respect
            parents and elders, and to abstain from killing. The words in
            which he exhorted his subjects are still relevant today:
          </p>
          <p className="body1">
            One should not honor only one's own religion and condemn other
            religions. Instead, one should honor other religions for various
            reasons. By so doing one helps one's own religion to grow and
            also renders service to the religions of others. In acting
            otherwise one digs the grave of one's own religion and harms
            other religions as well. Someone who honors his own religion and
            condemns other religions may do so out of devotionto his
            religion, thinking, 'I will glorify my religion'; but his
            actions injure his own religion more gravely. Concord is good.
            Let all listen and be willing to listen to the doctrines
            professed by others. (Rock Edict12)
          </p>
          <p className="body1">
            Emperor Ashoka represents a glorious tradition of tolerant
            co-existence and peaceful synthesis. That tradition lives on
            among governments and rulers today. An example is the noble
            monarch of Oman, who has donated land for churches and temples
            of other faiths while practicing his own religion with all
            devotion and diligence. I am sure that such compassionate rulers
            and governments will continue to arise in future in many lands
            around the world. As it is said, "Blessed are the peacemakers,
            for they shall be called sons of God."
          </p>
          <p className="body1">
            It is all too clear that the votaries of violence primarily hurt
            their own kith and kin. They may do so directly, through their
            intolerance, or indirectly, by provoking a violent response to
            their actions. On the other hand, it is said, "Blessed are the
            merciful, for they shall obtain mercy." This is the law of
            nature. It may equally be called the decree or way of God. The
            Buddha said, "Animosity can be eradicated not by animosity but
            only by its opposite. This is an eternal Dharma [spiritual
            law]." What is called Dharma in India has nothing to do with
            Hinduism, Buddhism, Jainism, Christianity, Islam, Judaism,
            Sikhism or any other "ism". It is this simple truth: before you
            harm others, you first harm yourself by generating mental
            negativity; and by removing the negativity, you can find peace
            within and strengthen peace in the world.
          </p>
          <h6>Peace of Mind For World Peace</h6>
          <p className="body1">
            Every religion worthy of the name calls on its followers to live
            a moral and ethical way of life, to attain mastery over the mind
            and to cultivate purity of heart. One tradition tells us, "Love
            thy neighbor"; another says, Salaam walekum - "May peace be with
            you"; still another says, Bhavatu sabbamangalam or Sarve
            bhavantu sukhinah - "May all beings be happy." Whether it is the
            Bible, the Koran or the Gita, the scriptures call for peace and
            amity. From Mahavir to Jesus, all great founders of religions
            have been ideals of tolerance and peace. Yet our world is often
            driven by religious and sectarian strife, or even war - because
            we give importance only to the outer shell of religion and
            neglect its essence. The result is a lack of love and compassion
            in the mind.
          </p>
          <p className="body1">
            Peace in the world cannot be achieved unless there is peace
            within individuals. Agitation and peace cannot co-exist. One way
            to achieve inner peace is Vipassana or insight meditation - a
            non-sectarian, scientific, results-oriented technique of
            self-observation and truth realization. Practice of this
            technique brings experiential understanding of how mind and body
            interact. Everytime negativity arises in the mind, such as
            hatred, it triggers unpleasant sensations within the body. Every
            time the mind generates selfless love, compassion and good will,
            the entire body is flooded with pleasant sensations. Practice of
            Vipassana also reveals that mental action precedes every
            physical and vocal action, determining whether that action will
            be wholesome or unwholesome. Mind matters most. That is why we
            must find practical methods to make the mind peaceful and pure.
            Such methods will amplify the effectiveness of the joint
            declaration emerging from this World Peace Summit.
          </p>
          <p className="body1">
            Ancient India gave two practices to the world. One is the
            physical exercise of yoga postures (Asanas) and breath control
            (Pranayama) for keeping the body healthy. The other is the
            mental exercise of Vipassana for keeping the mind healthy.
            People of any faith can and do practice both these methods. At
            the same time, they may follow their own religions in peace and
            harmony; there is no necessity for conversion, a common source
            of tension and conflict.
          </p>
          <p className="body1">
            For society to be peaceful, more and more members of society
            must be peaceful. As leaders, we have a responsibility to set an
            example, to be an inspiration. A sage once said, "A balanced
            mind is necessary to balance the unbalanced mind of others."
          </p>
          <p className="body1">
            More broadly, a peaceful society will find a way to live in
            peace with its natural setting. We all understand the need to
            protect the environment, to stop polluting it. What prevents us
            from acting on this understanding is the stock of mental
            pollutants, such as ignorance, cruelty or greed. Removing such
            pollutants will promote peace among human beings, as well as a
            balanced, healthy relationship between human society and its
            natural environment. This is how religion can foster
            environmental protection.
          </p>
          <h6>Non-Violence: the Key to a Definition of Religion</h6>
          <p className="body1">
            There are bound to be differences between religions. However, by
            gathering at this World Peace Summit, leaders of all the major
            faiths have shown that they want to work for peace. Let peace
            then be the first principle of "universal religion". Let us
            declare together that we shall abstain from killing, that we
            condemn violence. I also urge political leaders to join in this
            declaration, given the key role they play in bringing either
            peace or war. Whether or not they join us, at least let us all
            make avow here and now: instead of condoning violence and
            killing, let us declare that we unconditionally condemn such
            deeds, especially violence perpetrated in the name of religion.
          </p>
          <p className="body1">
            Certain spiritual leaders have had the sagacity and courage to
            condemn violence committed in the name of their own faith. There
            may be different philosophical and theological views of the act
            of seeking forgiveness or regretting past violence and killing;
            but the very acknowledgment of violence performed in the past
            implies that it was wrong and that it will not be condoned in
            future.
          </p>
          <p className="body1">
            <strong>
              Religion sets us not apart; it teaches peace and purity of
              heart.
            </strong>
          </p>
          <p className="body1">
            I congratulate the organizers of this historic Summit for their
            vision and efforts. And I congratulate the religious and
            spiritual leaders who have had the maturity to work for
            reconciliation, giving hope to humanity that religion and
            spirituality will lead to a peaceful future.
          </p>
          <p className="body1">
            May all beings be free from aversion and be happy.
          </p>
          <p className="body1">May peace and harmony prevail.</p>
        </div>
      </div>
    </div>
  );
};

export default GoenkaPage;
