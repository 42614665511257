import React from "react";
import "./AboutUs.css";
import Carousel from "../../../components/Carousel/Carousel";

import Home2 from "../../Home/assets/home2.jpg";
import Center2 from "../../TheCentre/assets/center2.jpg";
import Center3 from "../../TheCentre/assets/center3.jpg";
import Center4 from "../../TheCentre/assets/center4.jpg";
import Center5 from "../../TheCentre/assets/center5.jpg";
import Center6 from "../../TheCentre/assets/center6.jpg";
import Center7 from "../../TheCentre/assets/center7.jpg";

const Slides = [
  { img: Center2, label: "View of center from pagoda walking turf." },
  { img: Home2, label: "Aerial view of Dhamma Pattana" },
  { img: Center3, label: "View of center building from female walking area." },
  { img: Center4, label: "Male walking area." },
  { img: Center5, label: "Colorful plants beside walking path." },
  { img: Center6, label: "Walking landscape in background with greenery." },
  { img: Center7, label: "Male side walking path adjacent to building." },
];

const Overview = ({ onImageClick }) => {
  const handleOnImageClick = (index) => {
    onImageClick(Slides, index)
  }

  return (
    <div>
      <section className="section">
        <h2 className="section-title">About us</h2>
        <h2 className="section-title">Overview</h2>
        <p className="body1 all-txts">
          Dhamma Pattana is a three storeyed building (Ground + 3) spread over
          ___ acres of land comprising of air-conditioned Dhamma Hall and Pagoda
          respectively for Group and individual Meditation for 100+ students,
          Segregated Male and Female air-conditioned residences and a dining
          hall. Also has a landscaped garden for walking.
        </p>
        <Carousel slides={Slides} onImageClick={handleOnImageClick} />
      </section>
    </div>
  );
};

export default Overview;
