/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { createPortal } from 'react-dom';
import "./ImageModal.css";

const ImageModal = ({
  imageSrc,
  imageAlt,
  handlePrevClick,
  handleNextClick,
  handleClose,
}) => {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  });

  return (
    <>
      {createPortal(
        <div className="modal-overlay show" tabIndex="-1">
          <div className="modal-background" onClick={handleClose} />
          <div className="modal-content">
            <button className="modal-close" onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
                width="24px"
                height="24px"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
            <img src={imageSrc} alt={imageAlt} className="modal-image" />
            <button className="modal-arrow left-arrow" onClick={handlePrevClick}>
              &#10094;
            </button>
            <button className="modal-arrow right-arrow" onClick={handleNextClick}>
              &#10095;
            </button>
          </div>
        </div>,
        document.body
      )}
    </>

  );
};

export default ImageModal;
