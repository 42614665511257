import React from "react";
import "./IntroductoryVideos.css";

const IntroductoryVideos = () => {
  return (
    <div className="container custom-container mb-5">
      <h5 className="text-center mb-4">Introductory Videos</h5>
      <p className="body1 mb-4 text-center">
        For those who are not currently familiar with the ancient technique of
        Vipassana Meditation, its non-sectarian nature and its benefits, video
        discourses approximately 20 minutes in length are provided below.
      </p>

      <div className="row align-items-center my-4">
        <div className="col-lg-5 col-md-12">
          <div className="roadmap-video position-relative">
            <iframe
              src="https://www.youtube.com/embed/uhqSuAIDV34"
              title="Video"
              className="intro-one"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="col-lg-2 col-md-12 my-2 roadmap-info-wrap position-relative">
          <span className="position-absolute bg-color1 number">01</span>
        </div>
        <div className="col-lg-5 col-md-12 d-flex align-items-center vedio-text mt-3">
          <p className="body1  ">
            Vipassana Meditation (An Introduction By Mr. S. N. Goenka)-English
          </p>
        </div>
      </div>

      <div className="row align-items-center my-4 flex-column-reverse flex-md-row vedio-text ">
        <div className="col-lg-5 col-md-12 d-flex  align-items-center mt-3">
          <p className="body1 ">
            Vipassana Meditation (An Introduction By Mr. S. N. Goenka)-Hindi
            Address By Mr. S. N. Goenka at the United Nations: In late August
            2000, Goenkaji participated in the Millennium World Peace Summit—a
            gathering of 1000 of the world’s religious and spiritual leaders,
            held at the United Nations under the auspices of Secretary-General
            Kofi Annan. The purpose of the meeting was to promote tolerance,
            foster peace, and encourage inter-religious dialogue. With the many
            different viewpoints represented, the potential for disagreement was
            strong. In his presentation to the delegates, Goenkaji tried to
            highlight what they, and all spiritual paths have in common: the
            universal Dhamma. His remarks were received with repeated ovations.
          </p>
        </div>
        <div className="col-lg-2 col-md-12 roadmap-info-wrap position-relative my-2">
          <span className="position-absolute bg-color1 number">02</span>
        </div>
        <div className="col-lg-5 col-md-12">
          <div className="roadmap-video position-relative">
            <iframe
              src="https://www.youtube.com/embed/8o9CZwMN1aY?si=ncwZ00xFdM9K64d6"
              title="Video"
              className="intro-one"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      <div className="row align-items-center my-4">
        <div className="col-lg-5 col-md-12">
          <div className="roadmap-video position-relative">
            <iframe
              src="https://www.youtube.com/embed/P97criit1qI"
              title="Video"
              className="intro-one"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="col-lg-2 col-md-12 my-2 roadmap-info-wrap position-relative ">
          <span className="position-absolute bg-color1 number">03</span>
        </div>
        <div className="col-lg-5 col-md-12 d-flex  align-items-center vedio-text mt-3">
          <p className="body1">
            Vipassana in business: In 2009, a short film was produced that
            describes how senior business leaders in India from across
            industries & sectors cope with uncertainty and change in a
            challenging market environment. In a series of candid interviews,
            senior executives discuss how the practice of Vipassana meditation
            enables them to more effectively manage their own stress, increase
            employee engagement and productivity, face the pressures of
            competition and make decisions from a base of personal wisdom and
            authenticity. To see the film, please visit Vipassana in business.
          </p>
        </div>
      </div>
      <p className="body1 mb-4 text-center">
        For more videos related to Vipassana Meditation, please visit:
        <strong>
          <a
            href="https://www.vridhamma.org/media?field_category1_value=All&title="
            target="_blank"
            className="moreVideo text"
            rel="noreferrer"
          >
            {" "}
            More Videos
          </a>
        </strong>
      </p>
    </div>
  );
};

export default IntroductoryVideos;
