import "./ArrivalAndDeparture.css";

const ArrivalAndDeparture = () => {
  return (
    <div className="container custom-container mb-5">
      <h5 className="mb-4 text-center">Arrival & Departure</h5>
      <div className=" arrival-departure-container row justify-content-center">
        <div className="outer-box arrival-container  col-md-5 mb-4 d-flex ">
          <div className="inner-box arrival-inner-box ">
            <h5 className="mb-4">Arrival :</h5>
            <ul className="body1">
              <li>
                Please arrive between 2:00 pm to 5:00 pm. on the day the course
                begins.
              </li>
              <li>
                This allows time for you to get your accommodation and unpack.
                Late arrivals make it difficult for the management to serve
                everyone efficiently and to start the course on time. If you
                have an emergency and are unable to arrive at the requested
                time, please notify us as soon as possible.
              </li>
              <li>
                A light meal will be served at 6:00 pm followed by a pre-course
                orientation. Course will begin around 8 pm.
              </li>
            </ul>
          </div>
        </div>
        <div className="outer-box departure-container  col-md-5 mb-4 d-flex">
          <div className="inner-box departure-inner-box">
            <h5 className="mb-4">Departure :</h5>
            <p className="body1">
              Course will be ended at approximately 7:30 a.m. on the last day of
              the course.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArrivalAndDeparture;
